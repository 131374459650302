<template>
    <div class="layout-blank"></div>
</template>
<script>
export default {
   name:'BlankPage',
}
</script>
<style lang="less">
.layout-blank{
    float:left;
    width: 100%;
    height: 100%;
    background: url("../../assets/Image/blank.svg") no-repeat center center;
    background-size: 430px;
}
</style>
